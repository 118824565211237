<template>
    <div>
        <b-form>
            <b-row >
                <b-col md="2" xl="2" class="mb-2">
                    <b-button  variant="primary" @click="addNewLiquidation" block>
                        <feather-icon icon="PlusIcon" class="mr-50"/>Adicionar Liquidacion
                    </b-button>                        
                </b-col>                                      
            </b-row>
        </b-form>        

        <b-card-code>

            <b-card-title>
                <h2 class="mb-1">Liquidaciones del Proyecto #{{projectCode}}</h2>
            </b-card-title>

            <b-table
            ref="selectableTable"
            :fields="fields"
            :items="liquidations" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected"            
            >
                <template #cell(liquidationDate)="data">
                    {{data.item.liquidationDate.slice(0, 10)}}
                </template> 

                <template #cell(totalValue)="data">
                    $ {{parseFloat(data.item.totalValue != null ? data.item.totalValue : 0).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP
                </template> 

                <template #cell(liquidationType)="data">
                    <b-badge v-if="data.item.liquidationType == 'Final'" pill variant="danger" style="font-size:12px" >{{ data.item.liquidationType }}</b-badge>
                    <b-badge v-if="data.item.liquidationType == 'Parcial'" pill variant="success" style="font-size:12px" >{{ data.item.liquidationType }}</b-badge>
                </template>      

            </b-table>
        </b-card-code>

        <b-row v-if="liquidations.length == 0" >
            <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>Este proyecto no tiene liquidaciones</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal centered ref="buttons-liquidations-modal" hide-footer title="¿Que deseas hacer?">
            <b-button class="mt-1" variant="primary" block @click="viewLiquidation">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Liquidación</span>
            </b-button>
            <b-button class="mt-2" variant="warning" block @click="editLiquidation">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar Liquidación</span>
            </b-button> 
            <b-button class="mt-2" variant="danger" block @click="deleteLiquidation">
                <feather-icon icon="Trash2Icon" class="mr-50"/>
                <span class="align-middle">Eliminar Liquidación</span>
            </b-button>
        </b-modal>

        <b-modal size="sm" ref="liquidationDetailsModal" centered hide-footer title="Digita la siguiente información">            
            <b-card-text>                 
                <b-form>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <label for="example-datepicker">Fecha Liquidación</label>
                            <b-form-datepicker v-model="liquidationDate" id="example-datepicker1" class="mb-1"/>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <b-form-group label="Tipo de Evento" label-for="basicInput">                        
                                <b-form-select @change="liquidatonTypeSelected" v-model="liquidationType" :options="liquidationTypes" placeholder="Seleccione"/>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mb-0">
                            <label>Descripción</label>
                            <b-form-textarea v-model="description" id="textarea-default" placeholder="Digite la descripción" rows="3"/>
                        </b-col> 
                    </b-row>

                    <b-row>
                        <b-col md="12" xl="12" class="mt-1">
                            <label>Mensaje a Neoganaderos</label>
                            <b-form-textarea v-model="messageToInvestors" id="textarea-default" placeholder="Digite mensaje a Neoganaderos" rows="5"/>
                        </b-col> 
                    </b-row>

                    <b-row  class="mt-1 justify-content-end">
                        <b-col v-if="!processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" @click="saveProjectLiquidation" block>                                                            
                                Guardar
                            </b-button>
                        </b-col>
                        <b-col v-if="processing" class="mt-1 mb-1" md="12" lg="12">
                            <b-button variant="primary" disabled block>
                                <b-spinner small label="Cargando..." />
                            </b-button>
                        </b-col>
                    </b-row>                    
                </b-form>                
            </b-card-text>        
        </b-modal>

    </div>
</template>


<script>
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BProgress, BFormGroup, BFormSelect, BButton, 
BRow, BCol, BCardText, BCardTitle, BBadge, BFormDatepicker, BSpinner, BFormTextarea} from 'bootstrap-vue'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
    components: {
        BCardCode,
        BCardText,
        BTable,
        BForm,
        BProgress,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BAlert,
        BCardTitle,
        BBadge,
        BFormDatepicker,
        BSpinner,
        BFormTextarea
    },
    data(){
        return{
            processing: false,
            projectCode: '',
            selectMode: 'single',
            liquidationTypes: [
                'Parcial',
                'Final'
            ], 
            selectedLiquidation: {},
            projectID:'',
            project: {},
            fields: [
                { key: 'liquidationDate', label: 'Fecha' },   
                { key: 'liquidationType', label: 'Tipo' }, 
                { key: 'totalValue', label: 'Valor Total' },  
                { key: 'description', label: 'Descripción', thStyle:{ width: "25%"} },
                { key: 'messageToInvestors', label: 'Mensaje a NeoGanaderos', thStyle:{ width: "40%"} }
            ],
            liquidations: [{ }],
            liquidationDate: null,
            liquidationType: null,
            messageToInvestors: null,
            description: null,    
            projectLiquidationID: null,  
        }
    },
    created(){     
      if(this.$route != null && this.$route.query != null && this.$route.query.projectID != null) {
        this.projectID = this.$route.query.projectID; 
        this.GetProjectsLiquidations();
        this.GetProjectById();
      }
    },
    methods:{
        GetProjectById(){
            axios.get('/Project/GetProjectById',  { params: { projectID: this.projectID }})
                .then(response => {
                    this.project = response.data;
                    this.projectCode = this.project.projectCode;
                })
        },
        GetProjectsLiquidations(){   
            this.liquidations = [];         
            axios.get('/Liquidation/GetAllProjectLiquidations', { params: { projectID: this.projectID }})
             .then(response => {                
                this.liquidations = response.data;                
            })
        },
        onRowSelected(items) {
            this.selectedLiquidation = items[0];
            this.projectLiquidationID = this.selectedLiquidation.projectLiquidationID;                 
            this.showModal();
        },
        showModal() {
            this.$refs['buttons-liquidations-modal'].show()
        },
        viewLiquidation(){
            let route = this.$router.resolve({path: 'project-investments-liquidations?projectLiquidationID=' + this.selectedLiquidation.projectLiquidationID});
            window.open(route.href, '_blank');
        },
        editLiquidation() {
            this.liquidationDate = this.selectedLiquidation.liquidationDate;
            this.liquidationType = this.selectedLiquidation.liquidationType;
            this.messageToInvestors = this.selectedLiquidation.messageToInvestors;
            this.description = this.selectedLiquidation.description;
            this.projectLiquidationID = this.selectedLiquidation.projectLiquidationID;
            this.$refs['buttons-liquidations-modal'].hide()
            
            this.$refs['liquidationDetailsModal'].show()
        },
        deleteLiquidation(){
            axios.get('/Liquidation/DeleteProjectLiquidation', { params: { projectLiquidationID: this.projectLiquidationID }})
            .then(response => {
                this.$refs['buttons-liquidations-modal'].hide()
                this.GetProjectsLiquidations();
            }).catch(error => {                
                this.$refs['buttons-liquidations-modal'].hide()
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            });
        },
        addNewLiquidation(){   
            this.liquidationDate = null;
            this.liquidationType = null;
            this.messageToInvestors = null;
            this.description = null;
            this.projectLiquidationID = undefined;
            this.$refs['liquidationDetailsModal'].show()
        },
        liquidatonTypeSelected(event){
            this.liquidationType = event;
        },
        saveProjectLiquidation(){
            this.processing = true;
            var payload = {
                projectLiquidationID: this.projectLiquidationID,
                projectID: this.projectID,
                liquidationDate: this.liquidationDate,
                liquidationType: this.liquidationType,
                messageToInvestors: this.messageToInvestors,
                description: this.description                
            }
            
            axios.post('/Liquidation/SaveProjectLiquidation', payload)
            .then(response => {
                this.$bvToast.toast("Información ha sido Guardada", { title: 'Ok', variant: 'success', solid: true})                   
                this.$refs['liquidationDetailsModal'].hide();
                this.processing = false;   
                this.GetProjectsLiquidations();
            }).catch(error => {
                this.processing = false;
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
            
        }
    },
}
</script>